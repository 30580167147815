import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../../../components/ui/CrmSearch/CrmSearch";
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../../../components/ui/Button';
import { ArrowsClockwise, Info } from 'phosphor-react';
import 'react-toggle/style.css';
import './reload-button.scss';
import JokerMultiSelect from '../../../../../../components/ui/JokerMultiSelect/JokerMultiSelect';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_SIZE_WIDTH } from '../../../../../../constants/mobile';
import { getTradingAccList } from '../../../../../../api/tradingAcc';
import { useForm } from 'react-hook-form';
import { getTimesTamp } from '../../../../../../utils/getTimesTamp';
import { getNumberRange } from '../../../../../../utils/getNumberRange';
import DatePickerRange from '../../../../../../components/ui/DatePickerRange';
import FormInput from '../../../../../../components/ui/FormInput';

const cx = classNames.bind(styles);

const TradingActivityFilter = ({ onSubmit, reloadFilters, userId, tickerOpt, typeOpt, queryMessage}) => {
  const [accountsOpt, setAccountsOpt] = useState([]);

  const componentMounted = useRef(true);
  const width = useWindowWidth();

  const isMobile = () => width < MOBILE_SIZE_WIDTH;

  const getDefaultValue = () => {
    return {
      query: '',
      trading_account: [],
      trade_side: [],
      ticker: [],
      open_at: '',
      close_at: '',
      volume: {
        from: '',
        to: '',
      },
    };
  };

  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        trading_account: getValue(data['trading_account']),
        trade_side: getValue(data['trade_side']),
        symbol: getValue(data['ticker']),
        volume: getNumberRange(data['volume']),
        open_at: getTimesTamp(data['open_at']),
        close_at: getTimesTamp(data['close_at']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    onSubmit(null);
  };

  useEffect(() => {
    getTradingAccOpt();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTradingAccOpt = () => {
    getTradingAccList(null, userId).then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.trading_account,
          label: opt.name,
        }));
        setAccountsOpt(opt);
      }
    });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar searchOnly={true} className="mt-0" message={queryMessage}>
              <CrmSearch
                id='query'
                name='query'
                control={control}
                placeholder='Search by Trade ID'
                onSearch={() => submit(getValues())}
                handleChange={(value: string) => {
                  if (value?.length === 0) submit(getValues());
                }}
              />
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Accounts"
              control={control}
              id="trading_account"
              name="trading_account"
              options={accountsOpt}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label="Type"
              control={control}
              id="trade_side"
              name="trade_side"
              options={typeOpt}
              hideSearch={true}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              hideSearch={true}
              label="Symbol"
              control={control}
              id="ticker"
              name="ticker"
              options={tickerOpt}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <Row className={cx('range-wrapper')}>
              <Col md={6}>
                <FormInput
                  name="volume.from"
                  type="number"
                  placeholder="0.00"
                  min={0}
                  label="Volume"
                  control={control}
                />

                <span className={cx('range-divider')}>-</span>
              </Col>
              <Col md={6}>
                <FormInput
                  name="volume.to"
                  min={0}
                  type="number"
                  label="&nbsp;"
                  placeholder="0.00"
                  control={control}
                />
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="open_at"
              control={control}
              label="Open time range"
              placeholder="From - To"
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="close_at"
              control={control}
              label="Close time range"
              placeholder="From - To"
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!getDirtyFieldsLength()}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!getDirtyFieldsLength()}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TradingActivityFilter;
