import { ConfirmationModal } from "../../../../../components/ui/Modal/components/ConfirmationModal/ConfirmationModal";
import { deleteMessage } from "../../../../../store/slice/chat/thunks";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { selectModalProps } from "../../../../../store/slice/modalSlice";

export const REMOVE_MESSAGE_MODAL_KEY = 'REMOVE_MESSAGE_MODAL_KEY';

export const RemoveMessageModal = () => {
    const dispatch = useAppDispatch();
    const modalProps = useAppSelector(selectModalProps);

    const handleDeleteMessage = async () => {
        dispatch(deleteMessage({ id: modalProps?.id, threadId: modalProps?.threadId }));
    };

    return (
        <ConfirmationModal
            id={REMOVE_MESSAGE_MODAL_KEY}
            onConfirm={handleDeleteMessage}
            title='Remove this message?'
            size='sm'
        />
    )
}