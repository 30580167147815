import { createAction, createSlice } from "@reduxjs/toolkit";
import {
    ChatMessageType, ChatRecipientType,
    ChatUserInfo, OnlineStatusesVerbose,
    Participant,
    ProcessedMessageMode,
    Thread,
} from "../../../models/Chat";
import {
    createThread,
    deleteParticipant, getFoundMessagesList,
    getMessages,
    getNextMessages, getParticipants, getRecipient,
    getThreads, leaveGroup, promoteParticipant,
    updateText,
    updateUserInfo
} from "./thunks";

type ReduxAction<T> = {
    type: string;
    payload: T
}

type InitialState = {
    token: string;
    connected: 'init' | boolean;
    opened: boolean;
    userInfo: ChatUserInfo | null;
    unreadSupport: string[];
    unreadInternal: string[];
    recipient: ChatRecipientType | null;
    threadsPending: boolean;
    threadId: string | null;
    threads: Thread[];
    mutedThreads: Record<string, boolean>;
    messagesError: boolean;
    messagesPending: boolean;
    messagesNextPage: string | null;
    messages: ChatMessageType[];
    participants: Record<string, Participant>;
    processedMessage: ChatMessageType | null;
    processedMessageMode: ProcessedMessageMode | null;
    foundMessageId: string;
    scrollToTheLast: boolean;
    onlineUserStatuses: Record<string, number>;
};

const initialState: InitialState = {
    token: '',
    connected: 'init',
    opened: false,
    userInfo: null,

    unreadSupport: [],
    unreadInternal: [],
    onlineUserStatuses: {},

    recipient: null,
    threadsPending: true,
    threadId: null,
    threads: [],
    mutedThreads: {},

    messagesError: false,
    messagesPending: true,
    messagesNextPage: null,
    messages: [],
    participants: {},

    processedMessage: null,
    processedMessageMode: null,

    scrollToTheLast: true,
    foundMessageId: ''
};

export const selectChatToken = (state) => state.chat.token;
export const selectChatConnectionStatus = (state) => state.chat.connected;
export const selectChatOpened = (state) => state.chat.opened;
export const selectChatAuth = (state) => !!state.chat.userInfo;
export const selectChatUser = (state): ChatUserInfo => state.chat.userInfo;
export const selectChatRecipient = (state): ChatRecipientType | null => state.chat.recipient;
export const selectChatUserId = (state) => state.chat.userInfo?.provider?.provider_id;
export const selectUserStatuses = (state) => state.chat.onlineUserStatuses;
export const selectChatThreads = (state) => state.chat.threads;
export const selectThreadId = (state) => state.chat.threadId;
export const selectThread = (state) => state.chat.threads.find(thread => thread.id === state.chat.threadId);
export const selectMutedThreads = (state) => state.chat.mutedThreads;
export const selectChatLoading = (state) => state.chat.threadsPending;
export const selectChatUnreadThreads = (state) => state.chat.unreadSupport.length + state.chat.unreadInternal.length;
export const selectChatUnreadSupport = (state) => state.chat.unreadSupport.length;
export const selectChatUnreadInternal = (state) => state.chat.unreadInternal.length;
export const selectMessages = (state) => state.chat.messages;
export const selectMessagesError = (state) => state.chat.messagesError;
export const selectDialogParticipants = (state): Record<string, Participant> => state.chat.participants;
export const selectMessagesNextPage = (state) => state.chat.messagesNextPage;
export const selectMessagesLoading = (state) => state.chat.messagesPending;
export const selectProcessedMessageMode = (state) => state.chat.processedMessageMode;
export const selectProcessedMessage = (state) => state.chat.processedMessage;
export const selectScrollToTheLast = (state) => state.chat.scrollToTheLast;
export const selectFoundMessageId = (state) => state.chat.foundMessageId;

const getThreadUnreadType = (thread: Thread) => {
    return !!thread.is_support ? 'unreadSupport' : 'unreadInternal';
};

const decreaseUnreadCount = (state: InitialState, thread) => {
    const threadUnreadType = getThreadUnreadType(thread);
    const index = state[threadUnreadType].findIndex(id => id === thread.id);
    state[threadUnreadType].splice(index, 1);
};

const increaseUnreadCount = (state: InitialState, thread) => {
    const threadUnreadType = getThreadUnreadType(thread);
    if (!state[threadUnreadType].includes(thread.id)) {
        state[threadUnreadType].push(thread.id);
    }
};

const updateMessages = (state: InitialState, message: ChatMessageType) => {
    if (state.threadId === message.thread_id) {
        const index = state.messages.findIndex(m => m?.temporary_id === message.temporary_id);
        index === -1 ? state.messages.unshift(message) : state.messages[index] = message;
    }
};

const updateIncomingMessageThread = (state: InitialState, message: ChatMessageType) => {
    const index = state.threads.findIndex(thread => thread.id === message.thread_id);
    const thread = state.threads[index];
    const ownMessage = message.owner.provider_id === state.userInfo.provider.provider_id;

    thread.resources.latest_message = message;

    if (!ownMessage) {
        thread.unread = true;
        thread.unread_count += 1;
        increaseUnreadCount(state, thread);
    }

    if (!thread.pinned) {
        const newIndex = state.threads.findIndex(thread => !thread.pinned);
        state.threads.splice(newIndex, 0, state.threads.splice(index, 1)[0]);
    }
};

const getNewThreadIndex = (state: InitialState) => {
    if (!state.threads.length) {
        return 0;
    }

    const index = state.threads.findIndex(thread => !thread.pinned);
    return index > -1 ? index : state.threads.length -1;
};

export const chatInit = createAction<undefined>('chat/init');
export const chatAuth = createAction<undefined>('chat/login');
export const incomingThread = createAction<string>('chat/thread/requested');

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChatToken(state, action: ReduxAction<string>) {
            state.token = action.payload;
        },
        setChatOpened(state, action: ReduxAction<boolean>) {
            state.opened = action.payload;
        },
        setConnectionStatus(state, action: ReduxAction<boolean>) {
            state.connected = action.payload;
        },
        setThreadId(state, action: ReduxAction<string>) {
            state.threadId = action.payload;
        },
        addThread(state, action) {
            state.threads.splice(getNewThreadIndex(state), 0, action.payload);
            increaseUnreadCount(state, action.payload);
            if (!action.payload.group) {
                state.onlineUserStatuses[action.payload.resources.recipient.provider_id] = OnlineStatusesVerbose.ONLINE;
            }
        },
        removeThread(state, action: ReduxAction<{thread_id: string}>) {
            const threadIndex = state.threads.findIndex(thread => thread.id === action.payload.thread_id);
            if (state.threads[threadIndex].unread_count) {
                decreaseUnreadCount(state, state.threads[threadIndex]);
            }

            state.threads.splice(threadIndex, 1);

            if (state.threadId === action.payload.thread_id) {
                state.messagesError = true;
            }
        },
        readThread(state, action: ReduxAction<{thread_id: string, last_read: string}>) {
            const threadIndex = state.threads.findIndex(thread => thread.id === action.payload.thread_id);
            if (state.threads[threadIndex].resources?.latest_message?.owner_id !== state.userInfo.provider.provider_id) {
                decreaseUnreadCount(state, state.threads[threadIndex]);
                state.threads[threadIndex].unread = false;
                state.threads[threadIndex].unread_count = 0;
            }
        },
        setMutedThreads(state, action) {
            state.mutedThreads = action.payload;
        },
        muteThread(state, action: ReduxAction<string>) {
            state.mutedThreads[action.payload] = true;
        },
        unmuteThread(state, action: ReduxAction<string>) {
            state.mutedThreads[action.payload] = false;
        },
        setUserStatus(state, action: ReduxAction<ChatUserInfo>) {
            state.userInfo = action.payload;
        },
        setRecipient(state, action: ReduxAction<ChatRecipientType>) {
            state.recipient = action.payload;
            state.messagesPending = false;

            if (action.payload) {
                state.onlineUserStatuses[action.payload.provider_id] = action.payload.options.online_status;
            }
        },
        addNewMessage(state, action) {
            //TODO: CHAT - remove the condition when fixed undefined Thread
            if (action.payload) {
                state.onlineUserStatuses[action.payload.owner.provider_id] = OnlineStatusesVerbose.ONLINE;
                updateMessages(state, action.payload);
                updateIncomingMessageThread(state, action.payload);
            }
        },
        editMessage(state, action) {
            const index = state.messages.findIndex(message => message.id === action.payload.id);
            state.messages[index] = action.payload;
        },
        removeMessage(state, action) {
            if (state.threadId === action.payload.thread_id) {
                const index = state.messages.findIndex(message => message.id === action.payload.message_id);
                state.messages.splice(index, 1);
            }
        },
        addPendingMessage(state, action) {
            state.messages.unshift(action.payload);
            state.processedMessageMode = null;
            state.processedMessage = null;
            state.scrollToTheLast = true;
        },
        setProcessedMessage(state, action: ReduxAction<{mode: ProcessedMessageMode, message: ChatMessageType}>) {
            state.processedMessageMode = action.payload.mode;
            state.processedMessage = action.payload.message;
        },
        setMessagesError(state, action: ReduxAction<boolean>) {
            state.messagesError = action.payload;
        },
        addGroup(state, action) {
            if (state.threads.findIndex((thread: Thread) => thread.id === action.payload.id) === -1) {
                state.threads.splice(getNewThreadIndex(state), 0, action.payload);
            }
        },
        updateChatThreadSettings(state, action) {
            const threadIndex = state.threads.findIndex(thread => thread.id === action.payload.id);
            state.threads[threadIndex].name = action.payload.settings.name;
        },
        setFoundMessageId(state, action: ReduxAction<string>) {
            state.foundMessageId = action.payload;
        },
        setScrollToTheLast(state, action: ReduxAction<boolean>) {
            state.scrollToTheLast = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(updateUserInfo.fulfilled, (state, action) => {
            state.userInfo = action.payload;
            state.onlineUserStatuses[action.payload.provider.provider_id] = action.payload.online_status;
        });
        builder.addCase(getRecipient.fulfilled, (state, action) => {
            state.recipient = action.payload;
            state.onlineUserStatuses[action.payload.provider_id] = action.payload.options.online_status;
            state.messagesPending = false;
        });
        builder.addCase(createThread.pending, (state) => {
            state.messagesPending = true;
        });
        builder.addCase(createThread.fulfilled, (state, action) => {
            state.threads.splice(getNewThreadIndex(state), 0, action.payload);
            state.onlineUserStatuses[action.payload.resources.recipient.provider_id] = action.payload.resources.recipient.options.online_status;
            state.messagesPending = false;
        });
        builder.addCase(getThreads.fulfilled, (state, action) => {
            state.threadsPending = false;
            state.threads = action.payload.threads;
            state.unreadSupport = action.payload.unreadSupport;
            state.unreadInternal = action.payload.unreadInternal;
            state.onlineUserStatuses = Object.assign(state.onlineUserStatuses, action.payload.online);
        });
        builder.addCase(getMessages.pending, (state) => {
            state.messagesPending = true;
            state.messages = [];
            state.participants = {};
        });
        builder.addCase(getMessages.fulfilled, (state, action) => {
            state.messagesPending = false;
            state.messages = action.payload.messages;
            state.messagesNextPage = action.payload.next;
            state.scrollToTheLast = true;

            if (action.payload.participants) {
                state.participants = action.payload.participants;
                state.onlineUserStatuses = Object.assign(state.onlineUserStatuses, action.payload.online);
            }
        });
        builder.addCase(getNextMessages.fulfilled, (state, action) => {
            state.messages.push(...action.payload.messages);
            state.messagesNextPage = action.payload.next;
            state.scrollToTheLast = false;
        });
        builder.addCase(getMessages.rejected, (state) => {
            state.messagesPending = false;
            state.messagesError = true;
        });
        builder.addCase(updateText.pending, (state, action) => {
            const message = state.messages.find(message => message.id === action.meta.arg.id);
            message.body = action.meta.arg.body;
            state.scrollToTheLast = false;
        });
        builder.addCase(deleteParticipant.fulfilled, (state, action) => {
            delete state.participants[action.meta.arg.ownerId];
        });
        builder.addCase(promoteParticipant.fulfilled, (state, action) => {
            state.participants[action.payload.owner_id] = action.payload;
            state.onlineUserStatuses[action.payload.owner_id] = action.payload.owner.options.online_status;
        });
        builder.addCase(getParticipants.fulfilled, (state, action) => {
            state.participants = action.payload.participants;
            state.onlineUserStatuses = Object.assign(state.onlineUserStatuses, action.payload.online);
        });
        builder.addCase(leaveGroup.fulfilled, (state, action) => {
            const threadIndex = state.threads.findIndex(thread => thread.id === action.meta.arg);
            if (state.threads[threadIndex].unread_count) {
                const threadUnreadType = getThreadUnreadType(state.threads[threadIndex]);
                const index = state[threadUnreadType].findIndex(id => id === state.threads[threadIndex].id);
                state[threadUnreadType].splice(index, 1);
            }
            state.threads.splice(threadIndex, 1);
        });
        builder.addCase(getFoundMessagesList.pending, (state) => {
            state.messagesNextPage = null;
            state.messagesPending = true;
        });
        builder.addCase(getFoundMessagesList.fulfilled, (state, action) => {
            state.messages = action.payload;
            state.foundMessageId = action.meta.arg.id;
            state.messagesNextPage = action.payload[action.payload.length - 1]?.id;
            state.messagesPending = false;
        });
    }
});

const { reducer, actions } = chatSlice;

export const {
    setChatToken,
    setChatOpened,
    setConnectionStatus,
    setProcessedMessage,
    addPendingMessage,
    setRecipient,
    setMessagesError,
    setThreadId,
    setMutedThreads,
    muteThread,
    unmuteThread,
    removeMessage,
    editMessage,
    addNewMessage,
    removeThread,
    readThread,
    addThread,
    addGroup,
    updateChatThreadSettings,
    setFoundMessageId,
    setScrollToTheLast
} = actions;
export default reducer;