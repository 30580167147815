import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect, defaultQueryCategoryOption
} from "../../../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../../../utils/useGetSearchCategoriesOptions";
import styles from './filter.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowsClockwise, Info } from 'phosphor-react';
import { useForm, useWatch } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import moment from 'moment';
import Search from 'components/ui/Search/Search';
import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import JokerSelect from 'components/ui/JokerSelect';
import DatePickerRange from 'components/ui/DatePickerRange';
import { isFormChange } from '../../../../../../utils/isFormChange';
import { clearEmptyFilter } from "../../../../../../utils/clearEmptyFilter.ts";

const cx = classNames.bind(styles);

const CallbacksFilter = ({ onSubmit, reloadFilters, statusList, queryMessage}) => {
  const componentMounted = useRef(true);
  const callbackSearchOptions = useSearchCategoriesOptions('callbackByClient');

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      created_at: '',
      statuses: undefined,
    };
  };
  const { handleSubmit, reset, getValues, control, formState, watch } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, callbackSearchOptions);

  const prepareFiltersData = (data) => {
    let d_from = moment(data.created_at.from).startOf('day').toString();
    let d_to = moment(data.created_at.to).startOf('day').toString();
    const postData = data.created_at
      ? {
          filter: {
            time: { from: new Date(d_from).getTime() / 1000, to: new Date(d_to).getTime() / 1000 },
            statuses: getValue(data['statuses']) && getValue(data['statuses']),
          },
        }
      : {
          filter: {
            statuses: getValue(data['statuses']) && getValue(data['statuses']),
          },
        };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);

    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    onSubmit(null);
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar className="mt-0" message={queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={callbackSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerMultiSelect
              label={'Status'}
              control={control}
              id="statuses"
              name="statuses"
              options={statusList}
              hideSearch={true}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <DatePickerRange
              name="created_at"
              control={control}
              label={'Date & time range'}
              placeholder="From - To"
            />
          </Col>
          <Col>
            <div className={cx('filters__btns')}>
              <Button
                className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
                icon={<ArrowsClockwise color="var(--white)" size={18} />}
                onClick={(e) => {
                  e.preventDefault();
                  submit(getValues());
                }}
              />
              <Button
                buttonText="Reset"
                type="button"
                onClick={() => resetForm()}
                disabled={!isFormChange(dirtyFields)}
              />
              <Button
                buttonText="Apply"
                type="submit"
                onClick={handleSubmit(submit)}
                disabled={!isFormChange(dirtyFields)}
              />
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default CallbacksFilter;
