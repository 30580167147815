import classNames          from 'classnames/bind';
import React, { useState } from 'react';

import { Col, Row }           from 'react-bootstrap';
import { useForm }            from 'react-hook-form';
import { setStyleListMethod } from '../../../../api/settingsLK';
import { notify }             from '../../../../utils/notify.tsx';
import styles                 from './style.module.scss';
import Button from "../../../../components/ui/Button";
import CircleColorsPicker from "../../../../components/ui/CirclePicker/CirclePicker";
import { setColorsLK } from "../../../../utils/setColorsLK";
import { DEFAULT_COLORS_LK } from "../../../../constants/colors.js";
import { LKStyleList } from 'models/SettingsLK/LKStyleList';

const cx = classNames.bind(styles);

type Props = {
  formData: LKStyleList;
  updateComponent: () => void;
}

const SetColorsForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setDefaultValues = () => {
    return DEFAULT_COLORS_LK.DEFAULT
  };

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const onSubmit = (data): void => {
    setIsLoading(true);

    const sendData = setColorsLK(data?.currency_color)

    setStyleListMethod(sendData)
      .then((res): void => {
        if (res) {
          notify({
            type: 'success',
            message: 'Style changed successfully',
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };
  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col xs={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Set default colors
              {isDirty && <Button buttonText='Save changes' buttonType='outline' />}
            </div>
          </Col>
        </Row>
        {formData && (
          <div className={cx('form__line')}>
            <Col md={10} xs={12}>
              <CircleColorsPicker
                control={control}
                name='currency_color'
                selected_color={formData?.currency_color || '#fff'}
              />
            </Col>
          </div>
        )}
      </div>
      <br/>
    </form>
  );
};

export default SetColorsForm;
