import classNames from 'classnames/bind';
import { CaretDoubleDown, CaretDoubleUp } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { findClients } from '../../../api/clients';
import moment from 'moment';
import { fullDateTimeFormat } from '../../../components/ui/ModernDateRangeInput';
import Button from '../../../components/ui/Button';
import { ComplexSearchBar } from '../../../components/ui/ComplexSearchBar/ComplexSearchBar';
import { CrmSearch } from '../../../components/ui/CrmSearch/CrmSearch';
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption,
} from '../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect';
import ClientsTable from '../../../components/ui/Table/ClientsTable';
import TablePagination from '../../../components/ui/TablePagination';
import { BULK_ACTIONS_TYPE } from '../../../constants/clients/bulk-actions.const';
import useDynamicSearchPlaceholder from '../../../utils/useDynamicSearchPlaceholder';
import { useSearchCategoriesOptions } from '../../../utils/useGetSearchCategoriesOptions';
import { usePermission } from '../../../utils/usePermission';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import ClientFilter from './ClientFilter/ClientFilter';
import styles from './search-clients.module.scss';
import MoveBulkActionsPopup from './SearchClientsPopups/MoveBulkActionsPopup/MoveBulkActionsPopup';
import RetentionBulkActionsPopup from './SearchClientsPopups/RetentionBulkActionsPopup/RetentionBulkActionsPopup';
import SalesBulkActionsPopup from './SearchClientsPopups/SalesBulkActionsPopup/SalesBulkActionsPopup';
import { notify } from '../../../utils/notify';
import ChangeAcquisitionStatuses from './SearchClientsPopups/ChangeAcquisitionStatuses/ChangeAcquisitionStatuses';
import { useSelector } from 'react-redux';
import { getStatusProgress } from '../../../constants/selectors';
import { ProductionClientFilters } from 'models/Clients/ClientSearch';
import { Client } from 'models/Clients/Clients';
import { Sort } from 'models/Sort';
import { TableLinks, TableMeta } from 'models/Table';
import ProgressBarSection from './ProgressBarSection.tsx';
import { useAppDispatch } from '../../../store';
import { toggleScrollToTop } from '../../../store/slice/tableSlice.ts';
import { ArrowsClockwise } from 'phosphor-react';
import { useCheckAutoRefresh } from '../../../utils/useCheckAutoRefresh.tsx';
import { useForm } from 'react-hook-form';
import Search from '../../../components/ui/Search';
import { Question } from 'phosphor-react';
import { clearEmptyFilter } from '../../../utils/clearEmptyFilter';
import { DynamicFilterRequiredType } from 'models/Clients/ClientFilter';

const cx = classNames.bind(styles);

const SORTED_FIELD_NAME = {
  client: 'name',
  // last_activity: 'last_login', TODO: NO BACKEND REALIZATION;
  country: 'country',
  balance: 'balance',
  deposit: 'number_deposits',
  registration: 'created_at',
  last_note: 'note_last',
};

const SearchClients = () => {
  const { permissionGiven: PermissionListClient } = usePermission('admin.client.list');
  const { permissionGiven: PermissionMenu } = usePermission('admin.menu-clients.view', true);
  const { permissionGiven: PermissionBulkMoveToTeam } = usePermission('admin.client.bulk.to-teams');
  const { permissionGiven: PermissionBulkMoveToBuffer } = usePermission('admin.client.bulk.move');
  const { permissionGiven: PermissionBulkMoveToSalesTeam } =
    usePermission('admin.client.bulk.sales');
  const { permissionGiven: PermissionBulkMoveToRetentionTeam } = usePermission(
    'admin.client.bulk.retention',
  );

  const status = useSelector(getStatusProgress);
  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(false);
  const [clientsList, setClientsList] = useState<Client[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [selectedClientsType, setSelectedClientsType] = useState<string[]>([]);
  const [filters, setFilters] = useState<ProductionClientFilters | null>(null);
  const [tableMeta, setTableMeta] = useState<TableMeta | null>(null);
  const [tableLinks, setTableLinks] = useState<TableLinks | null>(null);
  const [perPageCount, setPerPageCount] = useState(200);
  const [sort, setSort] = useState<Sort>(undefined);
  const checkAutoRefresh = useCheckAutoRefresh('Page Search Clients');
  const [dynamicFilterList, setDynamicFilterList] = useState<DynamicFilterRequiredType[]>([]);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');

  const componentMounted = useRef(false);
  document.title = 'Search Clients';

  useEffect(() => {
    if (PermissionListClient) {
      componentMounted.current = true;
      getClientsList();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionListClient]);

  const changeLimits = (val) => {
    setPerPageCount(val);
  };

  const getClientsList = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findClients(getTablePageParams(pageParams), filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setClientsList(res?.data?.data);
          setTableMeta(res?.data?.meta);
          setTableLinks(res?.data?.links);
          setPerPageCount(res?.data?.meta?.per_page);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsListLoading(false));
  };

  const prepareFiltersData = (data) => {
    const dynamicStatuses = {};
    for (const item of data['dynamic_statuses']) {
      if (item.type === 'select') {
        dynamicStatuses[item.value] = getFilterValue(item[item.value]);
      }
      if (item.type === 'user') {
        dynamicStatuses[item.value] = getFilterValue(item[item.value]);
      }
    }
    let dynamic_default = { ...getDynamicStatusKey(data) };

    const getNumberRange = (data) => {
      if (!data) {
        return;
      }

      if (!data.from && !data.to) {
        return;
      }

      const res = { from: data.from !== '' ? Number(data.from) : 0 };

      if (data.to) {
        res['to'] = +data.to;
      }
      return res;
    };

    const postData = {
      filter: {
        languageIds: getFilterValue(data['languageIds']),
        countryIds: getFilterValue(data['countryIds']),
        deskIds: getFilterValue(data['deskIds']),
        teamIds: getFilterValue(data['teamIds']),
        first_time_deposit: getFilterValue(data['first_time_deposit']),
        assign_status: getFilterValue(data['assign_status']),
        profile_status: getFilterValue(data['profile_status']) && [
          getFilterValue(data['profile_status']),
        ],
        desk_type: getFilterValue(data['desk_type']) && [getFilterValue(data['desk_type'])],
        activity: getFilterValue(data['activity']),
        operatorIds: getFilterValue(data['operatorIds']),
        affiliateIds: getFilterValue(data['affiliateIds']),
        notes: getFilterValue(data['notes']),
        referrals: getFilterValue(data['referrals']),
        sources: getFilterValue(data['sources']),
        balance: getNumberRange(data['balance']),
        number_deposits: getNumberRange(data['number_deposits']),
        last_trade: formatFiltersByDateTimeRange(data['last_trade'], 'yyyy-MM-DD HH:mm:ss'),
        note_last: formatFiltersByDateTimeRange(data['note_last']),
        note_first: formatFiltersByDateTimeRange(data['note_first']),
        updated_at: formatFiltersByDateTimeRange(data['updated_at']),
        last_login: formatFiltersByDateTimeRange(data['last_login']),
        ftd_range: formatFiltersByDateTimeRange(data['ftd_range']),
        created_at: formatFiltersByDateTimeRange(data['created_at']),
        // limit: formatFiltersByDateTimeRange(data['limit']),
        ...dynamic_default,
        ...dynamicStatuses,
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const getFilterValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data)) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getDynamicStatusKey = (data?) => {
    let dynamicStatuses = {};
    if (data) {
      let dynamic_field = dynamicFilterList?.map((item) => {
        return { [item.value]: getFilterValue(data[item.value]) };
      });
      for (const item of dynamic_field) {
        dynamicStatuses = { ...dynamicStatuses, ...item };
      }
    } else {
      let dynamic_field = dynamicFilterList?.map((item) => {
        return { [item.value]: [] };
      });
      for (const item of dynamic_field) {
        dynamicStatuses = { ...dynamicStatuses, ...item };
      }
    }

    return dynamicStatuses;
  };

  const getDefaultValue = () => {
    let dynamic_default = { ...getDynamicStatusKey() };
    return {
      languageIds: [],
      countryIds: [],
      searchName: '',
      query: '',
      query_category: defaultQueryCategoryOption,
      searchById: '',
      searchMigration: '',
      deskIds: [],
      teamIds: [],
      first_time_deposit: undefined,
      assign_status: undefined,
      profile_status: undefined,
      desk_type: undefined,
      activity: undefined,
      operatorIds: [],
      affiliateIds: [],
      balance: {
        from: '',
        to: '',
      },
      number_deposits: {
        from: '',
        to: '',
      },
      last_trade: '',
      note_first: '',
      note_last: '',
      notes: '',
      sources: [],
      referrals: [],
      updated_at: '',
      last_login: '',
      ftd_range: '',
      created_at: '',
      // limit: '',
      custom_filters: '',
      dynamic_statuses: [],
      ...dynamic_default,
    };
  };

  const useFormReturn = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });

  const clientsSearchOptions = useSearchCategoriesOptions('client');
  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(
    useFormReturn.watch,
    useFormReturn.getValues,
    clientsSearchOptions,
  );

  const formatFiltersByDateTimeRange = (
    dateTimeRange: {
      from: string;
      to: string;
    },
    dateFormat = fullDateTimeFormat,
  ): {
    from: string;
    to: string;
  } | null => {
    return dateTimeRange && dateTimeRange.from && dateTimeRange.to
      ? {
          from: moment(dateTimeRange.from).format(dateFormat),
          to: moment(dateTimeRange.to).format(dateFormat),
        }
      : null;
  };

  const submitWithFilters = (data) => {
    const prepeared = prepareFiltersData(data);

    const postData: any = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    if (postData?.filter?.retention?.includes('new_retention')) {
      postData?.filter?.retention.push('new');
    }
    if (postData?.filter?.sales?.includes('new_sales')) {
      postData?.filter?.sales.push('new');
    }

    if (!filters && !postData) {
      return;
    }
    if (filters && !postData) {
      clearFiltersSortList();
    } else {
      setFilters(postData);
      getClientsList(
        { navigate: 'first', perPage: postData?.filter?.limit || perPageCount },
        { ...postData, sort },
      );
    }
    // if (data.limit) changeLimits(data.limit);
  };

  const getClientToBulkActions = (rows) => {
    const clients = [];
    const clientsWithType = [];
    rows?.forEach((item) => {
      clientsWithType.push(clientsList[item['index']]['type']);
      clients.push(clientsList[item['index']]['id']);
    });
    setSelectedRows(clients);
    setSelectedClientsType(clientsWithType);
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return `per_page=${perPageCount}&page=1`;
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splintedLink = tableLinks[navigate].split('?').reverse()[0];
      const splintedParams = splintedLink.split('&');
      toPage = splintedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(200);
    getClientsList({ navigate: 'first', perPage: 200 });
    setFilters(null);
  };

  const sortColumn = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;
    const sortedCountryAndClient =
      SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.country ||
      SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.client;
    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndClient ? 'asc' : 'desc',
      };
      setSort(sort);
      getClientsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndClient ? 'desc' : 'asc',
      };
      setSort(sort);
      getClientsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      setSort(undefined);
      getClientsList({ navigate: 'first' }, data);
      col.clearSortBy();
    }
  };

  useEffect(() => {
    if (status !== null) {
      getClientsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
    }
  }, [status]);

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header-wrapper')}>
            <div className={cx('container__header')}>
              <div className={cx('header', selectedRows?.length ? 'padding' : '')}>
                <div className={cx('search-result-clients')}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: selectedRows?.length ? 12 : 0,
                        gap: 8,
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                        <span className={cx('search-clients-amount-span')}>
                          <strong>{tableMeta?.total}</strong> Clients found
                        </span>
                        <span className={cx('search-clients-amount-span')}>
                          {selectedRows?.length} selected
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 8,
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          className={
                            isListLoading ? 'reload-btn-clients reload' : 'reload-btn-clients'
                          }
                          icon={<ArrowsClockwise color="var(--white)" size={18} />}
                          onClick={() => {
                            getClientsList(
                              {
                                navigate: 'first',
                                perPage: filters?.filter?.limit || perPageCount,
                              },
                              { ...filters, sort },
                            );
                          }}
                        />

                        <button
                          className={cx('hide-btn-clients')}
                          onClick={() => setExpanded(!expanded)}
                        >
                          {expanded && <CaretDoubleUp size={22} color="rgba(0, 0, 0, 0.1)" />}
                          {!expanded && <CaretDoubleDown size={22} color="rgba(0, 0, 0, 0.1)" />}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {selectedRows?.length ? (
                      <div className={cx('bulk-actions')} style={{ marginRight: 0 }}>
                        <span className={cx('search-client-bulk-actions-title')}>Bulk actions</span>
                        <div className={cx('bulk-actions__btn')}>
                          {PermissionBulkMoveToSalesTeam ? (
                            <ChangeAcquisitionStatuses
                              trigger={<Button buttonText="Change Acquisition Status Sales" />}
                              bulkActionsData={selectedRows}
                              selectedClients={selectedClientsType}
                              bulkActionsType={BULK_ACTIONS_TYPE.SALES}
                            />
                          ) : null}
                          {PermissionBulkMoveToRetentionTeam ? (
                            <ChangeAcquisitionStatuses
                              trigger={<Button buttonText="Change Acquisition Status Retention" />}
                              bulkActionsData={selectedRows}
                              selectedClients={selectedClientsType}
                              bulkActionsType={BULK_ACTIONS_TYPE.RETENTION}
                            />
                          ) : null}
                          {PermissionBulkMoveToSalesTeam ? (
                            <SalesBulkActionsPopup
                              trigger={<Button buttonText="Sales" />}
                              bulkActionsData={selectedRows}
                              bulkActionsType={BULK_ACTIONS_TYPE.SALES}
                              selectedClients={selectedRows}
                            />
                          ) : null}
                          {PermissionBulkMoveToRetentionTeam ? (
                            <RetentionBulkActionsPopup
                              trigger={<Button buttonText="Retention" />}
                              bulkActionsData={selectedRows}
                              bulkActionsType={BULK_ACTIONS_TYPE.RETENTION}
                              selectedClients={selectedRows}
                            />
                          ) : null}
                          <MoveBulkActionsPopup
                            trigger={<Button buttonText="Move" />}
                            bulkActionsData={selectedRows}
                            bulkActionsType={BULK_ACTIONS_TYPE.MOVE}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ComplexSearchBar message={queryResponseMessage}>
              <>
                <CrmSearchCategorySelect
                  id="query_category"
                  name="query_category"
                  control={useFormReturn.control}
                  options={clientsSearchOptions}
                  onSelectChange={() => {
                    const values = useFormReturn.getValues();
                    if (values?.query) {
                      submitWithFilters(useFormReturn.getValues());
                    }
                  }}
                />
                <CrmSearch
                  id="query"
                  name="query"
                  control={useFormReturn.control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={useFormReturn.handleSubmit(submitWithFilters)}
                  handleChange={(value: string) => {
                    if (value.length === 0) submitWithFilters(useFormReturn.getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </div>
          <div className={cx('container__block', !expanded && 'hide')} style={{ paddingTop: 20 }}>
            <ClientFilter
              // changeLimits={(v) => changeLimits(v)}
              reloadFilters={isListLoading}
              useFormReturn={useFormReturn}
              dynamicFilterList={dynamicFilterList}
              setDynamicFilterList={setDynamicFilterList}
              getDynamicStatusKey={getDynamicStatusKey}
              getDefaultValue={getDefaultValue}
              submit={submitWithFilters}
              expanded={expanded}
            />
          </div>
          <ProgressBarSection />
          <div className={cx('container__block-table')}>
            {PermissionListClient ? (
              <ClientsTable
                perPage={perPageCount}
                data={clientsList}
                showLoader={isListLoading}
                onRowSelect={getClientToBulkActions}
                expanded={expanded}
                manualSortHandler={sortColumn}
                modifyDataHandler={(callback) => console.log('a')}
              />
            ) : null}
            {clientsList?.length ? (
              <TablePagination
                perPage={perPageCount}
                goToFirstPage={() => {
                  getClientsList({ navigate: 'first' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                goToPrevPage={() => {
                  getClientsList({ navigate: 'prev' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                goToNextPage={() => {
                  getClientsList({ navigate: 'next' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                goToLastPage={() => {
                  getClientsList({ navigate: 'last' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                isRareCase={true}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getClientsList({ perPage: value }, getSortFiltersForPageNavigation());
                }}
                goToSelectedPage={(page) => {
                  getClientsList({ page }, getSortFiltersForPageNavigation());
                }}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : null}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default SearchClients;
