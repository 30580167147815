import { deleteImage } from "../api/settingsLK";
import { notify } from "../utils/notify.tsx";

export const removeImage = (type: string): void => {
  const postData = { key: type }

  deleteImage(postData)
    .then(res => {
      if (res.status === 200 && res.data.success) {
        notify({
          type: 'success',
          message: `${type === 'logo' ? 'Logo' : 'Image'} removed successfully`,
          timeOut: 3000,
        });
      }
    })
    .catch(err => {
      console.log(err)
      notify({
        type: 'error',
        message: `${type === 'logo' ? 'Logo' : 'Image'} not removed`,
        timeOut: 3000,
      });
    })
}
