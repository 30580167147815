import CryptoPaymentsPage     from 'pages/CustomerService/CryptoPayments/CryptoPaymentsPage';
import CryptoTransactionsPage from 'pages/CustomerService/CryptoTransactions/CryptoTransactionsPage';
import TransactionPage        from 'pages/CustomerService/TransactionPage/TransactionPage';
import WalletPage             from 'pages/CustomerService/WalletPage/WalletPage';
import LeadPage               from 'pages/Leads/Lead/LeadPage';
import SalesRulesPage         from 'pages/Management/SalesRules/SalesRulesPage';
import TemplatePage           from 'pages/Settings/MailTemplates/TemplatePage/TemplatePage';
import React                  from 'react';
import ForgotPassword         from '../pages/Auth/ForgotPassword';
import ResetPassword          from '../pages/Auth/ResetPassword';
import SignIn                 from '../pages/Auth/SignIn';
import CallbacksPage          from '../pages/Callbacks/CallbacksPage';
import Client                 from '../pages/Clients/Client/ClientPage';
import SearchClients          from '../pages/Clients/SearchClients';
import CryptoOutputPage       from '../pages/CustomerService/CryptoOutput';
import CryptoRequestPage      from '../pages/CustomerService/CryptoOutput/CryptoRequestPage';
import Dashboard              from '../pages/Dashboard';
import ErrorPage              from '../pages/ErrorPage/ErrorPage';
import HierarchyPage          from '../pages/Hierarchy';
import KycDocumentsPage       from '../pages/KycDocuments/KycDocumentsPage';
import SearchLeadsPage        from '../pages/Leads/SearchLeads';
import DesksPage              from '../pages/Management/Desks';
import OfficesPage            from '../pages/Management/Offices';
import OperatorsPage          from '../pages/Management/Operators';
import PartnersPage           from '../pages/Management/Partners/PartnersPage';
import TeamsPage              from '../pages/Management/Teams';
import NotificationsPage      from '../pages/Notifications/NotificationsPage';
import NotPermissionPage      from '../pages/NotPermissionPage/NotPermissionPage';
import OperatorPage           from '../pages/Management/Operators/Operator';
import PartnerPage            from '../pages/Management/Partners/Partner';
import PaymentsPage           from '../pages/Payments/PaymentsPage';
import PrivateErrorPage       from '../pages/PrivateErrorPage';
import RolesPermissionsPage   from '../pages/RolesPermissions';
import DynamicStatusTypesPage from '../pages/SettingsLk/dynamicStatuses';
import SettingsLkPage         from '../pages/SettingsLk/styleLk';
import TemplateRolePage       from '../pages/Templates';
import TradingAccPage         from '../pages/TradingAcc/TradingAccPage';
import SystemMessagesPage     from '../pages/SettingsLk/SystemMessages';

interface IRoute {
  id: string;
  path: string;
  component: React.ReactNode;
  title: string;
  permission: string;
}

export const ROUTES: IRoute[] = [
  {
    id: 'SIGN_IN',
    path: '/',
    component: <SignIn />,
    title: 'Авторизация',
    permission: 'all',
  },
  {
    id: 'RESET_PASSWORD',
    path: '/password/reset/:email/:code',
    component: <ResetPassword />,
    title: 'Сброс пароля',
    permission: 'all',
  },
  {
    id: 'RESET_PASSWORD_OPERATOR',
    path: '/email/:email/code/:code',
    component: <ResetPassword />,
    title: 'Сброс пароля',
    permission: 'all',
  },

  {
    id: 'FORGOT_PASSWORD',
    path: '/forgot-password',
    component: <ForgotPassword />,
    title: 'Востановить пароль',
    permission: 'all',
  },
  {
    id: 'ERROR_PAGE',
    path: '*',
    component: <ErrorPage />,
    title: 'Страница не найдена',
    permission: 'all',
  },
  {
    id: 'NOT_PERMISSION_PAGE',
    path: '/no-access',
    component: <NotPermissionPage />,
    title: 'Страница не доступна',
    permission: 'all',
  }
];

export const PRIVAT_ROUTES: IRoute[] = [
  // {
  //   id: 'SIGN_IN',
  //   path: '/',
  //   component: <SignIn />,
  //   title: 'Авторизация',
  //   permission: 'all',
  // },

  {
    id: 'HOME_PAGE',
    path: '/dashboard',
    component: <Dashboard />,
    title: 'Личный кабинет',
    permission: 'all',
  },
  {
    id: 'SEARCH_CLIENTS_PAGE',
    path: '/search-clients',
    component: <SearchClients />,
    title: 'Search clients',
    permission: 'all',
  },
  {
    id: 'CLIENT_PAGE',
    path: '/client/:id',
    component: <Client />,
    title: 'Client',
    permission: 'all',
  },
  {
    id: 'KYC_DOCUMENTS_PAGE',
    path: '/documents',
    component: <KycDocumentsPage />,
    title: 'KYC documents',
    permission: 'all',
  },
  {
    id: 'NOTIFICATIONS_PAGE',
    path: '/notifications',
    component: <NotificationsPage />,
    title: 'Notifications',
    permission: 'all',
  },
  {
    id: 'LEAD_PAGE',
    path: '/search-leads',
    component: <SearchLeadsPage />,
    title: 'Leads',
    permission: 'all',
  },
  {
    id: 'LEAD_PAGE',
    path: '/lead/:id',
    component: <LeadPage />,
    title: 'Lead',
    permission: 'all',
  },
  {
    id: 'SALES_RULES_PAGE',
    path: '/sales-rules',
    component: <SalesRulesPage />,
    title: 'SalesRules',
    permission: 'all',
  },
  {
    id: 'MANAGEMENT_TEAMS_PAGE',
    path: '/management-teams',
    component: <TeamsPage />,
    title: 'Teams',
    permission: 'all',
  },
  {
    id: 'MANAGEMENT_OFFICES_PAGE',
    path: '/management-offices',
    component: <OfficesPage />,
    title: 'Offices',
    permission: 'all',
  },
  {
    id: 'MANAGEMENT_DESKS_PAGE',
    path: '/management-desks',
    component: <DesksPage />,
    title: 'DESKS',
    permission: 'all',
  },
  {
    id: 'MANAGEMENT_OPERATORS_PAGE',
    path: '/management-operators',
    component: <OperatorsPage />,
    title: 'OPERATORS',
    permission: 'all',
  },
  {
    id: 'MANAGEMENT_PARTNERS_PAGE',
    path: '/management-partners',
    component: <PartnersPage />,
    title: 'PARTNERS',
    permission: 'all',
  },
  {
    id: 'OPERATOR_PAGE',
    path: '/operator/:id',
    component: <OperatorPage />,
    title: 'OPERATOR',
    permission: 'all',
  },
  {
    id: 'PARTNER_PAGE',
    path: '/partner/:id',
    component: <PartnerPage />,
    title: 'Partner',
    permission: 'all',
  },

  {
    id: 'CUSTOMER_SERVICE_CRYPTO_PAYMENTS_PAGE',
    path: '/customer-crypto',
    component: <CryptoPaymentsPage />,
    title: 'CRYPTOPAYMENTS',
    permission: 'all',
  },
  {
    id: 'CUSTOMER_SERVICE_CRYPTO_WALLET',
    path: '/customer-crypto/:id',
    component: <WalletPage />,
    title: 'Wallet',
    permission: 'all',
  },
  {
    id: 'CUSTOMER_SERVICE_CRYPTO_OUTPUT_PAGE',
    path: '/crypto-output/',
    component: <CryptoOutputPage />,
    title: 'Crypto output',
    permission: 'all',
  },
  {
    id: 'CUSTOMER_SERVICE_CRYPTO_WALLET',
    path: '/crypto-request/:id',
    component: <CryptoRequestPage />,
    title: 'Request',
    permission: 'all',
  },
  {
    id: 'CUSTOMER_SERVICE_CRYPTO_TRANSACTIONS_PAGE',
    path: '/customer-transactions',
    component: <CryptoTransactionsPage />,
    title: 'Crypto transactions',
    permission: 'all',
  },
  {
    id: 'CUSTOMER_SERVICE_CRYPTO_TRANSACTION_PAGE',
    path: '/customer-transactions/:id',
    component: <TransactionPage />,
    title: 'Crypto transaction page',
    permission: 'all',
  },
  {
    id: 'RESET_PASSWORD_OPERATOR',
    path: '/email/:email/code/:code',
    component: <ResetPassword />,
    title: 'Сброс пароля',
    permission: 'all',
  },
  // {
  //   id: 'MAIL_TEMPLATES',
  //   path: '/settings-templates',
  //   component: <MailTemplatesPage />,
  //   title: 'Email templates',
  //   permission: 'all',
  // },
  // {
  //   id: 'MAIL_TEMPLATE_NEW',
  //   path: '/settings-template',
  //   component: <TemplatePage />,
  //   title: 'Email templates',
  //   permission: 'all',
  // },
  {
    id: 'HIERARCHY_PAGE',
    path: '/hierarchy',
    component: <HierarchyPage />,
    title: 'Hierarchy',
    permission: 'all',
  },
  {
    id: 'ROLES_PERMISSIONS_PAGE',
    path: '/roles-end-permissions',
    component: <RolesPermissionsPage />,
    title: 'RolesTab and permissions',
    permission: 'all',
  },
  {
    id: 'MAIL_TEMPLATES_EDIT',
    path: '/settings-template/:id',
    component: <TemplatePage />,
    title: 'Email templates',
    permission: 'all',
  },
  {
    id: 'ERROR_PAGE',
    path: '*',
    component: <PrivateErrorPage />,
    title: 'Страница не найдена',
    permission: 'all',
  },
  {
    id: 'TRADING_ACC_PAGE',
    path: '/trading-accounts',
    component: <TradingAccPage />,
    title: 'Trading accounts',
    permission: 'all',
  },
  {
    id: 'PAYMENTS_PAGE',
    path: '/payments',
    component: <PaymentsPage />,
    title: 'Payments',
    permission: 'all',
  },
  {
    id: 'TEMPLATE_ROLES_PAGE',
    path: '/template-roles',
    component: <TemplateRolePage />,
    title: 'Template roles',
    permission: 'all',
  },
  {
    id: 'CALLBACKS_PAGE',
    path: '/callbacks/list',
    component: <CallbacksPage />,
    title: 'Callback',
    permission: 'all',
  },
  {
    id: 'SETTINGS_LK_PAGE',
    path: '/settings-pc-style',
    component: <SettingsLkPage />,
    title: 'Settings LK page',
    permission: 'all',
  },
  {
    id: 'SETTINGS_LK_DYNAMIC_STATUSES',
    path: '/settings-pc-dynamic-statuses',
    component: <DynamicStatusTypesPage />,
    title: 'Dynamic statuses',
    permission: 'all',
  },
  {
    id: 'SETTINGS_LK_SYSTEM_MESSAGES',
    path: '/settings-pc-system-messages',
    component: <SystemMessagesPage />,
    title: 'System messages',
    permission: 'all',
  },
];
