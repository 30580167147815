import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import Button from 'components/ui/Button';
import styles from './style.module.scss';
import { notify } from 'utils/notify';
import { uploadLogoMethod } from '../../../../api/settingsLK';
import UploadImageLK from '../../../../components/ui/UploadImageLK/UploadImageLK';
import emptyCache from "../../../../utils/emptyCache";
import { LKImage, LKStyleList } from 'models/SettingsLK/LKStyleList';

const cx = classNames.bind(styles);

type Props = {
  formData: LKStyleList;
  updateComponent: () => void;
  label: string;
  type: string;
}

const ImageForm = (props: Props) => {
  const { formData, updateComponent, label, type } = props;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [image, setImage] = useState<LKImage>(null);

  const [showBtn, setShowBtn] = useState<boolean>(false);
  useEffect((): void => {
    formData && setIsLoading(false);
  }, [formData]);
  const onSubmit = (): void => {
    setIsLoading(true);
    const formData: FormData = new FormData();
    formData.append('file', image.file);
    formData.append('key', type);

    uploadLogoMethod(formData)
      .then((res): void => {
        if (res) {
          notify({
            type: 'success',
            message: 'Image updated successfully',
            timeOut: 3000,
          });
          updateComponent();
          emptyCache();
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally((): void => {
        setIsLoading(false);
        setShowBtn(false);
      });
  };
  const changeImage = (image): void => {
    setImage(image);
    image ? setShowBtn(true) : setShowBtn(false);
  };
  return (
    <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
      <Row className={cx('form__line')}>
        <Col md={12}>
          <div className={cx('tab-panel__content-box-title')}>
            {label}
            {showBtn && (
              <Button buttonText='Save changes' buttonType='outline' onClick={() => onSubmit()} />
            )}
          </div>
        </Col>
      </Row>
      {formData && (
        <Col>
          <Row className={cx('form__line ')}>
            <UploadImageLK
              formData={formData}
              changeImage={(image) => changeImage(image)}
              image={formData[type]}
              type={type}
            />
          </Row>
        </Col>
      )}
      <Row>
        <Col className={cx('pt-2', 'pb-2', 'lh-sm')}>
          Only .png files are supported. The maximum size of a single file is 1 MB.
        </Col>
      </Row>
    </div>
  );
};

export default ImageForm;
