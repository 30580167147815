import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getStyleListMethod } from '../../../api/settingsLK/index';
import ButtonReset from '../../../components/ui/Button/ButtonReset';
import Page from '../../Page/index.js';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import ColorsForm from './forms/ColorsForm';
import LogoForm from './forms/LogoForm';
import ResetColorsPopup from './ResetColorsPopup/index.js';
import styles from './style.module.scss';
import SetColorsForm from "./forms/SetColorsForm";
import ImageForm from "./forms/ImageForm";
import { Col, Row } from "react-bootstrap";
import { LKStyleList } from 'models/SettingsLK/LKStyleList';
import { getEnableLK } from '../../../constants/selectors';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);
const SettingsLkPage = () => {
  const title = 'Styles';
  const [styleList, setStyleList] = useState<LKStyleList>();
  const ENABLE_LK = useSelector(getEnableLK);

  useEffect(() => {
    document.title = title;
  }, [title]);
  const fetchStyle = () => {
    getStyleListMethod().then((res) => {
      if (res.status === 200) {
        setStyleList(res.data.data);
      }
    }).catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchStyle();
  }, []);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>Styles</span>
                {ENABLE_LK && (
                  <ResetColorsPopup
                    triggerBtn={<ButtonReset buttonText='Reset all colors' buttonType={'outline'} />}
                    updateComponent={fetchStyle}
                  />
                )}
              </div>
            </div>
          </div>
          {styleList && (
            ENABLE_LK ? (
              <>
                <div className={cx('container__block')}>
                  <div className={cx('container__block-colors')}>
                    <div className={cx('colors-item')}>
                      <ColorsForm updateComponent={fetchStyle} formData={styleList} />
                    </div>
                    <div className={cx('colors-item')}>
                      <SetColorsForm updateComponent={fetchStyle} formData={styleList} />
                    </div>
                  </div>
                  <Row style={{ marginBottom: 36 }}>
                    <Col md={4} sm={8}>
                      <LogoForm updateComponent={fetchStyle} formData={styleList} additionalInfo='CRM' type="CRM" />
                    </Col>
                    <Col md={4} sm={8}>
                      <LogoForm updateComponent={fetchStyle} formData={styleList} additionalInfo='PC' type="PC" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4} sm={8}>
                      <ImageForm
                        updateComponent={fetchStyle}
                        formData={styleList}
                        label={'Upload image for Sign Up page'}
                        type={'sign_up'}
                      />
                    </Col>
                    <Col md={4} sm={8}>
                      <ImageForm
                        updateComponent={fetchStyle}
                        formData={styleList}
                        label={'Upload image for Sign In page'}
                        type={'sign_in'}
                      />
                    </Col>
                    <Col md={4} sm={8}>
                      <ImageForm
                        updateComponent={fetchStyle}
                        formData={styleList}
                        label={'Upload image for Reset Password page'}
                        type={'reset_password'}
                      />
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <>
                <div className={cx('container__block')}>
                  <Col md={6} sm={12}>
                    <LogoForm updateComponent={fetchStyle} formData={styleList} type="CRM" />
                  </Col>
                </div>
              </>
            )
          )}
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default SettingsLkPage;
