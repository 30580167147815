import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';

import { ChromePicker } from 'react-color';
import { useController } from 'react-hook-form';

import styles from './colorPicker.module.scss';

const cx = classNames.bind(styles);

const ColorPicker = (props) => {
  const { field } = useController(props);
  const { label } = props;
  const [showPicker, setShowPicker] = useState<boolean>(false);
  const [color, setColor] = useState<string>(props.defaultValue ? props.defaultValue : '#ffffff');
  useEffect((): void => {
    setColor(props.defaultValue);
  }, [props.defaultValue]);

  const handleClick = (): void => {
    setShowPicker(!showPicker);
  };

  const handleClose = (): void => {
    setShowPicker(false);
  };

  const handleChange = (value): void => {
    const { r, g, b, a } = value.rgb;
    setColor(value.hex);
    field.onChange(value.hex);
  };

  // useEffect((): void => {
  //   color !== props.defaultValue && field.onChange(color);
  // }, [color]);

  return (
    <div className={cx('colorPicker')}>
      <label className={cx('label')}>{label}</label>
      <div className={cx('colorPicker__swatch')} onClick={handleClick}>
        <div className={cx('colorPicker__color')} style={{ background: color }}></div>
      </div>
      {showPicker && (
        <div className={cx('colorPicker__popover')}>
          <div className={cx('colorPicker__cover')} onClick={handleClose} />
          <ChromePicker
            disableAlpha
            // defaultView={props.defaultValue}
            color={color}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};
export default ColorPicker;
