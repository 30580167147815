import { Trash } from "phosphor-react";
import { openJokerModal } from "../../../../store/slice/modalSlice";
import { REMOVE_MESSAGE_MODAL_KEY } from "../modals/RemoveMessageModal/RemoveMessageModal";
import { useAppDispatch } from "../../../../store";
import { MenuOption } from "./MenuOption";

type DeleteMessageOptionProps = {
    id: string;
    threadId: string
};

export const DeleteMessageOption = ({ id, threadId }: DeleteMessageOptionProps) => {
    const dispatch = useAppDispatch();

    const handleOpenDeleteMessageModal = () => {
        dispatch(openJokerModal({ key: REMOVE_MESSAGE_MODAL_KEY, data: { id, threadId } }));
    };

    return (
        <MenuOption title='Delete message' onClick={handleOpenDeleteMessageModal}>
            <Trash size={32} color="#c71d27"/>
        </MenuOption>
    )
}