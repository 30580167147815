import classNames from 'classnames/bind';
import React, { useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { setStyleListMethod } from '../../../../api/settingsLK/index';
import ColorPicker from '../../../../components/ui/ColorPicker/index.js';
import { notify } from '../../../../utils/notify.tsx';
import styles from './style.module.scss';
import Button from '../../../../components/ui/Button/index.js';
import { COLORS } from '../../../../constants/colors.js';
import { LKStyleList } from 'models/SettingsLK/LKStyleList.js';

const cx = classNames.bind(styles);

type Props = {
  formData: LKStyleList;
  updateComponent: () => void;
};

const ColorsForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(false);
  const setDefaultValues = () => {
    return {
      body_background_color: formData?.body_background_color
        ? formData?.body_background_color
        : '#ffffff',
      border_background_color: formData?.border_background_color
        ? formData?.border_background_color
        : '#ffffff',
      header_background_color: formData?.header_background_color
        ? formData?.header_background_color
        : '#ffffff',
      sidebar_background_color: formData?.sidebar_background_color
        ? formData?.sidebar_background_color
        : '#ffffff',
      main_color: formData?.main_color ? formData?.main_color : '#ffffff',
      main_light_color: formData?.main_light_color ? formData?.main_light_color : '#ffffff',
      main_color_hover: formData?.main_color_hover ? formData?.main_color_hover : '#ffffff',
      primary_text_color: formData?.primary_text_color ? formData?.primary_text_color : '#ffffff',
      secondary_text_color: formData?.secondary_text_color
        ? formData?.secondary_text_color
        : '#ffffff',
      disabled_text_color: formData?.disabled_text_color
        ? formData?.disabled_text_color
        : '#ffffff',
      currency_color: '#ffffff', //COLORS.CURRENCY_COLOR,
    };
  };

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
    };
    setStyleListMethod(sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Style changed successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col xl={6} sm={10} xs={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Colors
              {isDirty && <Button buttonText="Save changes" buttonType="outline" />}
            </div>
          </Col>
        </Row>
        {formData && (
          <div className={cx('form__line ')}>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Body background color'}
                defaultValue={formData.body_background_color}
                control={control}
                name="body_background_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Border color'}
                defaultValue={formData.border_background_color}
                control={control}
                name="border_background_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Header background color'}
                defaultValue={formData.header_background_color}
                control={control}
                name="header_background_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Sidebar background color'}
                defaultValue={formData.sidebar_background_color}
                control={control}
                name="sidebar_background_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Main color'}
                defaultValue={formData.main_color}
                control={control}
                name="main_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Main light color'}
                defaultValue={formData.main_light_color}
                control={control}
                name="main_light_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Main color hover'}
                defaultValue={formData.main_color_hover}
                control={control}
                name="main_color_hover"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Primary text color'}
                defaultValue={formData.primary_text_color}
                control={control}
                name="primary_text_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Secondary text color'}
                defaultValue={formData.secondary_text_color}
                control={control}
                name="secondary_text_color"
              />
            </Col>
            <Col xl={6} xs={10}>
              <ColorPicker
                label={'Disabled text color'}
                defaultValue={formData.disabled_text_color}
                control={control}
                name="disabled_text_color"
              />
            </Col>
          </div>
        )}
      </div>
    </form>
  );
};

export default ColorsForm;
