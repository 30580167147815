import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../components/ui/CrmSearch/CrmSearch";
import { CrmSearchCategorySelect } from "../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../utils/useGetSearchCategoriesOptions";
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import DatePickerRange from 'components/ui/DatePickerRange';
import moment from 'moment';
import { isFormChange } from '../../../utils/isFormChange';
import { CallbackFilter } from 'models/Callbacks/CallbackFilter';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: CallbackFilter) => void;
  reloadFilters: boolean;
  queryMessage: string,
}

const CallbacksFilter = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage} = props;

  const componentMounted = useRef(true);
  const callbacksSearchOptions = useSearchCategoriesOptions('callback');

  const getDefaultValue = () => {
    return {
      query: '',
      created_at: '',
      statuses: undefined,
    };
  };
  const { handleSubmit, reset, getValues, control, formState, watch } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, callbacksSearchOptions);

  const prepareFiltersData = (data) => {
    let d_from = moment(data.created_at.from).startOf('day').toString();
    let d_to = moment(data.created_at.to).startOf('day').toString();
    const postData = data.created_at
      ? {
          filter: {
            time: { from: new Date(d_from).getTime() / 1000, to: new Date(d_to).getTime() / 1000 },
            statuses: getValue(data['statuses']) && getValue(data['statuses']),
          },
        }
      : {
          filter: {
            statuses: getValue(data['statuses']) && getValue(data['statuses']),
          },
        };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData: CallbackFilter = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(submit);
      }}
      className={cx('filters')}
    >
      <Row>
        <Col md={12}>
          <ComplexSearchBar message={queryMessage}>
            <>
              <CrmSearchCategorySelect
                id='query_category'
                name='query_category'
                control={control}
                options={callbacksSearchOptions}
                onSelectChange={() => {
                  const { query } = getValues();
                  if (query) submit(getValues());
                }}
              />
              <CrmSearch
                id='query'
                name='query'
                control={control}
                placeholder={dynamicSearchPlaceholder}
                onSearch={() => submit(getValues())}
                handleChange={(value: string) => {
                  if (value?.length === 0) submit(getValues());
                }}
              />
            </>
          </ComplexSearchBar>
        </Col>
      </Row>
      <Row>
        <Col xl={3} lg={4} md={6}>
          <JokerMultiSelect
            label={'Status'}
            control={control}
            id="statuses"
            name="statuses"
            options={[
              {
                value: 'success',
                label: 'success',
              },
              {
                value: 'pending',
                label: 'pending',
              },
              {
                value: 'disabled',
                label: 'disabled',
              },
            ]}
          />
        </Col>
        <Col xl={3} lg={4} md={6}>
          <DatePickerRange
            name="created_at"
            control={control}
            label={'Date & time range'}
            placeholder="From - To"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default CallbacksFilter;
