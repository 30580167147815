import React, { FC, useEffect } from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { CloudArrowUp, Trash } from 'phosphor-react';
import { IUploadImageComponent } from "../../../models/components/UploadImageComponent/UploadImageComponent.ts";
import { deleteImage } from "../../../api/settingsLK";
import { notify } from "../../../utils/notify.tsx";
import { removeImage } from "../../../helpers/deleteImageForLK.ts";

const cx = classNames.bind(styles);

const UploadImageComponent: FC<IUploadImageComponent> = ({ changeLogo, logo }) => {
  const [images, setImages] = React.useState([]);
  const maxNumber: number = 69;
  const maxFileSize: number = 1050000;

  useEffect((): void => {
    logo && setImages([logo]);
  }, [logo]);

  const onChange = (imageList): void => {
    setImages(imageList);
    changeLogo(imageList[0]);
  };

  return (
    <div>
      <ImageUploading
        multiple={false}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={maxFileSize}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your building UI
          <div className={cx('wrapper-uploaderImage')}>
            <div className={cx('file-uploader')}>
              <div className={cx('file-uploader__files')}>
                <div className={cx('file-uploader__area')}>
                  {images.length === 0 ? (
                    <button
                      className={cx('file-uploader__title')}
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <i className={cx('file-uploader__icon')}></i>
                      Drag & Drop to Upload logo
                    </button>
                  ) : (
                    imageList.map((image: ImageType, index: number) => (
                      <div key={index} className="image-item">
                        <img src={image?.data_url ? image.data_url : image} alt="" width="100" />
                        <div className={cx('image-item__btn-wrapper')}>
                          <button
                            className={cx('actionBtn')}
                            type="button"
                            onClick={(): void => {
                              onImageUpload();
                            }}
                          >
                            <CloudArrowUp size={32} />
                          </button>
                          <button
                            className={cx('actionBtn')}
                            type="button"
                            onClick={(): void => {
                              onImageRemove(index);
                              removeImage('logo')
                            }}
                          >
                            <Trash size={32} color={'red'} />
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {errors?.maxFileSize &&
              <span className={cx('error__maxFileSize')}>
                The selected file is larger than 1 MB.
              </span>}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
export default UploadImageComponent;
