import { BROWN, COLORS, PURPLE, DEFAULT_COLORS_LK, GREEN, ORANGE, BLUE, LIGHT_BLUE } from "../constants/colors.js";

export const setColorsLK = (color) => {
  switch (color) {
    case BROWN:
      return DEFAULT_COLORS_LK.BROWN
    case PURPLE:
      return DEFAULT_COLORS_LK.PURPLE
    case GREEN:
      return DEFAULT_COLORS_LK.GREEN
    case ORANGE:
      return DEFAULT_COLORS_LK.ORANGE
    case BLUE:
      return DEFAULT_COLORS_LK.BLUE
    case LIGHT_BLUE:
      return DEFAULT_COLORS_LK.LIGHT_BLUE
    default:
      return {
        disabled_text_color: COLORS.DISABLED_TEXT_COLOR,
        header_background_color: COLORS.HEADER_BACKGROUND_COLOR,
        main_color: COLORS.MAIN_COLOR,
        main_color_hover: COLORS.MAIN_COLOR_HOVER,
        main_light_color: COLORS.MAIN_LIGHT_COLOR,
        primary_text_color: COLORS.PRIMARY_TEXT_COLOR,
        secondary_text_color: COLORS.SECONDARY_TEXT_COLOR,
        sidebar_background_color: COLORS.SIDEBAR_BACKGROUND_COLOR,
        body_background_color: COLORS.MAIN_LIGHT_COLOR,
        border_background_color: COLORS.MAIN_COLOR,
        currency_color: COLORS.DEFAULT_COLOR,
      }
  }
};
